exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-alexa-35-js": () => import("./../../../src/pages/alexa-35.js" /* webpackChunkName: "component---src-pages-alexa-35-js" */),
  "component---src-pages-alexa-mini-js": () => import("./../../../src/pages/alexa-mini.js" /* webpackChunkName: "component---src-pages-alexa-mini-js" */),
  "component---src-pages-cinemagic-revolution-js": () => import("./../../../src/pages/cinemagic-revolution.js" /* webpackChunkName: "component---src-pages-cinemagic-revolution-js" */),
  "component---src-pages-contato-js": () => import("./../../../src/pages/contato.js" /* webpackChunkName: "component---src-pages-contato-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-innovision-hd-js": () => import("./../../../src/pages/innovision-hd.js" /* webpackChunkName: "component---src-pages-innovision-hd-js" */),
  "component---src-pages-innovision-hr-js": () => import("./../../../src/pages/innovision-hr.js" /* webpackChunkName: "component---src-pages-innovision-hr-js" */),
  "component---src-pages-laowa-12-js": () => import("./../../../src/pages/laowa-12.js" /* webpackChunkName: "component---src-pages-laowa-12-js" */),
  "component---src-pages-laowa-24-js": () => import("./../../../src/pages/laowa-24.js" /* webpackChunkName: "component---src-pages-laowa-24-js" */),
  "component---src-pages-laowa-24-t-8-js": () => import("./../../../src/pages/laowa-24-T8.js" /* webpackChunkName: "component---src-pages-laowa-24-t-8-js" */),
  "component---src-pages-phantom-flex-4-k-js": () => import("./../../../src/pages/phantom-flex-4k.js" /* webpackChunkName: "component---src-pages-phantom-flex-4-k-js" */),
  "component---src-pages-sobre-nos-js": () => import("./../../../src/pages/sobre-nos.js" /* webpackChunkName: "component---src-pages-sobre-nos-js" */),
  "component---src-pages-videos-js": () => import("./../../../src/pages/videos.js" /* webpackChunkName: "component---src-pages-videos-js" */)
}

